import React from 'react';
import {Link, graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'


import MainLayout from '../layout/mainLayout';
import Head from '../components/utils/head'

 
const Projects = () => {
  // const [hovered, setHovered] = useState(false);
  // const toggleHover = () => setHovered(!hovered);
  // onMouseEnter={toggleHover} onMouseLeave={toggleHover}
  // ${hovered ? 'onHover' : ''} 
  
  const data = useStaticQuery(graphql`
  query {
    allContentfulProject{
      edges {
        node {
          image {
            file {
              url
            }
          }
          projectName
          projectType
          slug
        }
      }
    }
  }
  `)

  return ( 
    <MainLayout>
      <Head title="Projects"/>
        <div className="projects-container">
          <h1>Projects</h1>¨
          <div className="projects-grid-wrapper">
            <div className="projects-grid">
              {data.allContentfulProject.edges.map(p => {
                return (
                  <Link to={`/projects/${p.node.slug}`} >
                    <div className="project-card-component" >
                      <img src={p.node.image.file.url} alt={p.node.image.file.fileName}/>

                      <div className="project-card-component__description">
                        <div className={`gold-hover-effect`} ></div>
                        <div className="project-card-component__description-text">
                          <h2>{p.node.projectName}</h2>
                          <p>{p.node.projectType}</p>
                        </div>
                        {/* <FontAwesomeIcon icon={faLongArrowAltRight} /> */}
                      </div>
                      {/* <p className="project-read-more">Read More</p> */}
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
    </MainLayout>
  )
}

export default Projects;